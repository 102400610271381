import { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { StepOne, StepTwo } from "./components";

export default function Onboarding() {
  const modalRef = useRef(null);
  const initialToken = localStorage.getItem("completedOnboarding");

  const [step, setStep] = useState(0);
  // Until we've configured GDPR and user accounts,
  // we need this set as false to always display on page load.
  // Once we can store Verra terms approval, state default value
  // should be changed to the initialToken localStorage value.
  const [doNotDisplay, setDisplay] = useState(false);

  useEffect(() => {
    if (!doNotDisplay && modalRef.current) {
      modalRef.current.focus();
    }
  }, [doNotDisplay]);

  const onKeyDown = (event) => {
    event.stopPropagation();
    if (event.keyCode === 27) {
      onClick();
    }
  };

  const onClick = () => {
    if (!initialToken) {
      localStorage.setItem("completedOnboarding", "true");
    }
    setDisplay(true);
  };

  if (doNotDisplay) {
    return;
  }

  const stepOneProps = {
    setStep,
    onClick,
    onKeyDown,
    doNotDisplay,
  };

  const stepTwoProps = {
    onClick,
    onKeyDown,
    doNotDisplay,
  };

  return (
    <div
      className={parentContainerStyle}
      ref={modalRef}
      onKeyDown={onKeyDown}
      tabIndex={0}
    >
      {step === 0 ? (
        <StepOne {...stepOneProps} />
      ) : (
        <StepTwo {...stepTwoProps} />
      )}
    </div>
  );
}

const parentContainerStyle = clsx(
  "h-screen absolute z-[1200] backdrop-blur-sm bg-[black]/50 w-full pointer-events-auto"
);
