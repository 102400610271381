import clsx from "clsx";

// panel styles
export const panelContainer = clsx(
  "absolute bottom-0 w-full lg:!w-auto transition-transform duration-300 lg:h-full"
);

// mobile styles
const mobile = clsx("lg:hidden w-full transition-all duration-300");
export const mobileStaticBody = clsx(mobile + " fixed h-5/6 overflow-y-auto");
export const panelTitleMobile = clsx(
  `h-fit w-full inline-flex justify-between items-center p-4 bg-black text-white text-xl font-semibold`
);
export const mobileTitle = clsx("flex w-full justify-between items-center");
export const mobilePanelContainer = clsx(
  `lg:hidden bg-white z-50 absolute bottom-0 w-full bg-white shadow-lg transition-transform duration-300`
);

// desktop styles
export const panelDesktopContainer = clsx(
  `scrollStyle hidden lg:block max-h-[95%] absolute z-[70] mt-4 ml-4 rounded-md w-[410px] overflow-y-auto overflow-x-hidden`
);
