import clsx from "clsx";

/* WHEN MAKING EDITS TO THIS PAGE, PLEASE PLUG INTO MOBILE DEVICE TO TEST
   LAYOUT LOCALLY TO ASSURE ELEMENTS LOOK OKAY ON A SMALL SCREEN */

export const screen = clsx("relative h-[100dvh] w-full overflow-hidden lg:flex lg:flex-row");
export const elementsOnTopOfMap = clsx("absolute inset-0 flex flex-col pointer-events-none");
export const searchAndLayer = clsx(
  "flex justify-center lg:justify-end lg:pr-4 relative lg:mb-auto pointer-events-auto"
);
export const layerButtonMobile = clsx(
  "lg:hidden flex items-center mt-6 ml-2 z-10 pointer-events-auto"
);
export const dropdownContentsContainer = clsx(
  "lg:hidden w-full px-3 max-w-[390px] md:max-w-[520px] absolute mt-20 pointer-events-auto"
);
export const buttonBottomRight = clsx(
  "absolute bottom-48 lg:bottom-14 right-4 z-10 lg:block space-y-2.5"
);
