import * as styles from "./styles";
import * as components from "../components";
import * as dropdown from "../components/Dropdown";
import { QuestionMarkIcon, LayersIcon } from "../components/Icons";
import * as constants from "../constants";
import * as context from "../contexts";

export default function MapBase() {
  const { currentSelection } = context.useCurrentSelection();
  const { currentModal } = context.useCurrentModal();

  //------------------------//
  //--- MODAL PROPERTIES ---//
  //------------------------//
  const modalProps = {
    title: currentModal.modalTitle,
    desc: currentModal.modalDesc,
    isOpen: currentModal.modalIsOpen,
    onRequestClose: currentModal.closeModal,
  };

  //-------------------------//
  //--- BUTTON PROPERTIES ---//
  //-------------------------//
  const buttonProps = {
    onClick: () => {
      currentModal.openModal(
        constants.questionMarkModal.title,
        "",
        constants.questionMarkModal.contents
      );
    },
    backgroundColor: "whiteButton",
    textColor: "black",
    roundedSize: "rounded-md",
  };

  //------------------------//
  //--- LAYER PROPERTIES ---//
  //------------------------//
  const layerDropdownProps = {
    onClick: null, // the dropdown component will handle the action of the button
    backgroundColor: "whiteButton",
    textColor: "black",
    roundedSize: "rounded-md",
    className: "dropdownTitleElement",
  };

  const dropdownProps = {
    icon: (
      <components.Button {...layerDropdownProps} width={10}>
        <LayersIcon className="dropdownTitleElement" />
      </components.Button>
    ),
    isOpen: currentSelection.layerDropdownIsOpen,
    setIsOpen: () =>
      currentSelection.setLayerDropdownIsOpen(
        !currentSelection.layerDropdownIsOpen
      ),
  };

  const dropdownContentsProps = {
    options: constants.layerOptions,
    isOpen: currentSelection.layerDropdownIsOpen,
    selected: currentSelection.selectedLayer,
    handleItemClick: currentSelection.changeLayer,
  };

  //-----------------------//
  //--- RENDER MAP BASE ---//
  //-----------------------//

  return (
    <div className={styles.screen}>
      {/* Full-screen map background */}
      <div className="flex-1">
        <components.Mapbox />
      </div>

      {/* All elements above map */}
      <div className={styles.elementsOnTopOfMap}>
        <components.Onboarding />

        <div className={styles.searchAndLayer}>
          <context.SearchProvider>
            <components.Search />
          </context.SearchProvider>

          <components.MapTitle title={constants.mapTitle} />

          {/* Mobile-only button next to Search */}
          <div className={styles.layerButtonMobile}>
            <dropdown.Dropdown {...dropdownProps} />
          </div>

          {/* Mobile-only Layer button will display dropdown contents under search/button */}
          <div className={styles.dropdownContentsContainer}>
            <dropdown.Body {...dropdownContentsProps} />
          </div>
        </div>

        {/* Info buttons */}
        <div className={styles.buttonBottomRight}>
          <components.Button {...buttonProps}>
            <QuestionMarkIcon />
          </components.Button>
        </div>
      </div>

      <components.MetricsPanel>
        {currentSelection.selectedLayer.product && <components.PanelContents />}
      </components.MetricsPanel>

      {/* Modal */}
      <components.Modal {...modalProps}>
        {currentModal.modalContent}
      </components.Modal>
    </div>
  );
}
