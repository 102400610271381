import { useState, useEffect } from "react";
import clsx from "clsx";
import { ExpandArrowIcon } from "../Icons";
import * as styles from "./styles";

export default function MetricsPanel({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  // Prevent page scrolling when the panel is open
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "";
  }, [isOpen]);

  const panelBodyMobile = clsx(
    styles.mobileStaticBody +
      ` ${isOpen ? "translate-y-0" : "h-0 translate-y-full"}`
  );

  /* Metrics Panel (Side on Desktop, Bottom on Mobile) */
  return (
    <div className={styles.panelContainer}>
      <div
        className={styles.panelDesktopContainer}
        style={{ scrollbarGutter: "stable" }}
      >
        {children}
      </div>
      {/* Sliding Panel */}
      <div
        className={`${styles.mobilePanelContainer} 
          ${
            isOpen
              ? "translate-y-0 h-[90dvh]"
              : "translate-y-[calc(100%-60px)] h-[60px]"
          }`}
      >
        {/* Black Metrics Panel Title */}
        <div
          className={styles.panelTitleMobile}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className={styles.mobileTitle}>
            <div>Metrics</div>{" "}
            <ExpandArrowIcon
              fill="white"
              rotate={isOpen}
              alt={isOpen ? "close metrics" : "open metrics"}
            />
          </div>
        </div>

        {/* Panel Content */}
        <div className={panelBodyMobile}>{children}</div>
      </div>
    </div>
  );
}
